import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Flex, Box, Image } from '@chakra-ui/react';
import { useChatStore } from '@/web/core/chat/storeChat';
import { useTranslation } from 'next-i18next';
import Badge from '../Badge';
import MyIcon from '../Icon';

const NavbarPhone = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { lastChatAppId, lastChatId, setLastChatAppId, setLastChatId } = useChatStore();
  const navbarList = useMemo(
    () => [
      {
        label: 'AI问答',
        icon: '/imgs/hr/wd.png',
        appId: '6587080bbd4793d0c1379b74',
        shareId: '5ekzd51lsqg9akdkqasnddwd',
        activeIcon: '/imgs/hr/wd_s.png'
      },
      {
        label: '制度检查',
        icon: '/imgs/hr/zd.png',
        appId: '658fc6bb37d78a82911d9d75',
        shareId: 'syvetxdj0u0akuyesw4mk6oa',
        activeIcon: '/imgs/hr/zd_s.png'
      }
    ],
    []
  );
  //切换菜单
  function changeMenu(item: any) {
    setLastChatAppId(item.appId);
    setLastChatId(item.shareId);
  }

  return (
    <>
      <Flex
        alignItems={'center'}
        h={'100%'}
        justifyContent={'space-around'}
        backgroundColor={'white'}
        position={'relative'}
        px={10}
      >
        {navbarList.map((item) => (
          <Flex
            position={'relative'}
            key={item.appId}
            cursor={'pointer'}
            borderRadius={'md'}
            textAlign={'center'}
            flexDirection={'column'}
            alignItems={'center'}
            h={'100%'}
            pt={1}
            px={3}
            transform={'scale(0.9)'}
            {...(item.appId == lastChatAppId
              ? {
                  color: '#7089f1'
                }
              : {
                  color: 'myGray.500'
                })}
            _after={
              item.appId == lastChatAppId
                ? {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    borderRadius: '50%',
                    w: '18px',
                    h: '18px',
                    bg: ' #6782f1',
                    filter: 'blur(10px)',
                    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)'
                  }
                : {}
            }
            onClick={() => {
              if (item.appId == lastChatAppId) return;
              changeMenu(item);
            }}
          >
            <Image
              src={item.appId == lastChatAppId ? (item.activeIcon as any) : (item.icon as any)}
              alt=""
              width={'20px'}
              height={'20px'}
            ></Image>
            <Box fontSize={'12px'}>{item.label}</Box>
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default NavbarPhone;
