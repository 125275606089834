import React, { useMemo } from 'react';
import { Box, Flex, Link, Image } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useUserStore } from '@/web/support/user/useUserStore';
import { useChatStore } from '@/web/core/chat/storeChat';
import { HUMAN_ICON } from '@fastgpt/global/common/system/constants';
import { feConfigs } from '@/web/common/system/staticData';
import NextLink from 'next/link';
import Badge from '../Badge';
import Avatar from '../Avatar';
import MyIcon from '../Icon';
import { useTranslation } from 'next-i18next';
import { useSystemStore } from '@/web/common/system/useSystemStore';
import MyTooltip from '../MyTooltip';
import { getDocPath } from '@/web/common/system/doc';

export enum NavbarTypeEnum {
  normal = 'normal',
  small = 'small'
}

//危险货物，应用ID:6587080bbd4793d0c1379b74,shareId:5ekzd51lsqg9akdkqasnddwd
//制度检查，用用ID:658fc6bb37d78a82911d9d75,shareId:syvetxdj0u0akuyesw4mk6oa

const Navbar = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { userInfo } = useUserStore();
  const { gitStar } = useSystemStore();
  const { lastChatAppId, lastChatId, setLastChatAppId, setLastChatId } = useChatStore();
  const navbarList = useMemo(
    () => [
      {
        label: 'AI问答',
        icon: '/imgs/hr/wd.png',
        appId: '6587080bbd4793d0c1379b74',
        shareId: '5ekzd51lsqg9akdkqasnddwd',
        activeIcon: '/imgs/hr/wd_s.png'
      },
      {
        label: '制度检查',
        icon: '/imgs/hr/zd.png',
        appId: '658fc6bb37d78a82911d9d75',
        shareId: 'syvetxdj0u0akuyesw4mk6oa',
        activeIcon: '/imgs/hr/zd_s.png'
      }
    ],
    []
  );
  //切换菜单
  function changeMenu(item: any) {
    setLastChatAppId(item.appId);
    setLastChatId(item.shareId);
  }
  if (!lastChatAppId || !navbarList.some((v) => v.appId === lastChatAppId)) {
    //如果没有选中，就选中
    changeMenu(navbarList[0]);
  }

  const itemStyles: any = {
    my: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    w: '54px',
    h: '54px',
    borderRadius: 'md',
    _hover: {
      bg: 'myWhite.600'
    }
  };

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      pt={6}
      bg={'white'}
      h={'100%'}
      w={'100%'}
      boxShadow={'2px 0px 8px 0px rgba(0,0,0,0.1)'}
      userSelect={'none'}
    >
      {/* logo */}
      {/* <Box
        flex={'0 0 auto'}
        mb={5}
        border={'2px solid #fff'}
        borderRadius={'50%'}
        overflow={'hidden'}
        cursor={'pointer'}
        onClick={() => router.push('/account')}
      >
        <Avatar
          w={'36px'}
          h={'36px'}
          borderRadius={'50%'}
          src={userInfo?.avatar}
          fallbackSrc={HUMAN_ICON}
        />
      </Box> */}
      {/* 导航列表 */}
      <Box flex={1}>
        {navbarList.map((item) => (
          <Box
            key={item.appId}
            {...itemStyles}
            {...(item.appId == lastChatAppId
              ? {
                  color: 'blue.600',
                  bg: 'white !important',
                  boxShadow: '1px 1px 10px rgba(0,0,0,0.2)'
                }
              : {
                  color: 'myGray.500',
                  backgroundColor: 'transparent'
                })}
            {...(item.appId != lastChatAppId
              ? {
                  onClick: () => {
                    changeMenu(item);
                  }
                }
              : {})}
          >
            <Image
              src={item.appId == lastChatAppId ? (item.activeIcon as any) : (item.icon as any)}
              alt=""
              width={'20px'}
              height={'20px'}
            ></Image>
            <Box fontSize={'12px'} transform={'scale(0.9)'} mt={'5px'} lineHeight={1}>
              {item.label}
            </Box>
          </Box>
        ))}
      </Box>
      {(feConfigs?.docUrl || feConfigs?.chatbotUrl) && (
        <MyTooltip label={t('common.system.Use Helper')} placement={'right-end'}>
          <Link
            {...itemStyles}
            href={'/docs/危险货物AI智能服务平台使用说明书.pdf'}
            target="_blank"
            mb={0}
            color={'#9096a5'}
          >
            <MyIcon name={'common/courseLight'} width={'26px'} height={'26px'} />
          </Link>
        </MyTooltip>
      )}
    </Flex>
  );
};

export default Navbar;
